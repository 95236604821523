var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.window > 1017)?_c('div',[_c('div',{staticClass:"flex-row align-center",attrs:{"id":"dashboard-container"}},[_c('div',{staticClass:"rounded-l-pill",attrs:{"id":"dashboard-container2"}},[_c('div',{attrs:{"id":"rightSideDashboard"}},[_c('v-img',{staticClass:"db-logo",attrs:{"src":require("@/assets/sparky.png"),"max-height":"75","max-width":"75"}}),_c('div',{attrs:{"id":"navigations"}},[_c('v-btn',{staticClass:"nav-btn",class:this.$route.name == 'home'
                  ? 'primary--text text--darken-1'
                  : '',attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeRoutes('/home')}}},[_vm._v(" Dashboard ")]),_c('v-btn',{staticClass:"nav-btn",class:this.$route.name == 'studentApplications'
                  ? 'primary--text text--darken-1'
                  : '',attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeRoutes('/studentApplications')}}},[_vm._v(" Application ")]),_c('v-btn',{staticClass:"nav-btn",class:this.$route.name == 'applicationForms'
                  ? 'primary--text text--darken-1'
                  : '',attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeRoutes('/applicationForms')}}},[_vm._v(" Programs ")])],1),_c('div',{staticClass:"pa-8 rounded-l-pill",attrs:{"id":"dashboard-container3"}},[_c('v-btn',{staticClass:"main-action",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeRoutes('/profile')}}},[_c('v-icon',{staticStyle:{"color":"#00a99e"},attrs:{"aria-hidden":"false"}},[_vm._v(" mdi-account-circle ")]),(_vm.window > 847)?_c('div',[_vm._v("     "+_vm._s(this.user.displayName)+" ")]):_vm._e()],1),_c('v-icon',{staticStyle:{"color":"white","font-size":"xx-large"},attrs:{"aria-hidden":"false"},on:{"click":function($event){return _vm.signOut()}}},[_vm._v(" mdi-logout ")])],1)],1)])])]):_vm._e(),(_vm.window <= 1017)?_c('div',[_c('div',{staticClass:"flex-row align-center",attrs:{"id":"dashboard-container"}},[_c('div',{staticClass:"rounded-l-pill",attrs:{"id":"dashboard-container2"}},[_c('div',{attrs:{"id":"rightSideDashboard"}},[_c('div',{staticClass:"text-center"},[_c('v-img',{staticClass:"db-logo",attrs:{"src":require("@/assets/sparky.png"),"max-height":"75","max-width":"75"}})],1),_c('div',{staticClass:"pa-8 rounded-l-pill",attrs:{"id":"dashboard-container3"}},[_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"white"},attrs:{"large":"","aria-hidden":"false"}},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-down-drop-circle-outline ")])]}}],null,false,725652209)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{staticClass:"nav-btn",class:this.$route.name == 'home'
                        ? 'primary--text text--darken-1'
                        : '',attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeRoutes('/home')}}},[_vm._v(" Dashboard ")])],1),_c('v-list-item',[_c('v-btn',{staticClass:"nav-btn",class:this.$route.name == 'studentApplications'
                        ? 'primary--text text--darken-1'
                        : '',attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeRoutes('/studentApplications')}}},[_vm._v(" Application ")])],1),_c('v-list-item',[_c('v-btn',{staticClass:"nav-btn",class:this.$route.name == 'applicationForms'
                        ? 'primary--text text--darken-1'
                        : '',attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeRoutes('/applicationForms')}}},[_vm._v(" Programs ")])],1),_c('v-list-item',[_c('v-btn',{staticClass:"main-action",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.changeRoutes('/profile')}}},[_c('v-icon',{staticStyle:{"color":"#00a99e"},attrs:{"aria-hidden":"false"}},[_vm._v(" mdi-account-circle ")]),(_vm.window > 847)?_c('div',[_vm._v("     "+_vm._s(this.user.displayName)+" ")]):_vm._e()],1)],1),_c('v-list-item',[_c('v-btn',{staticClass:"main-action",attrs:{"elevation":"0"}},[_c('v-icon',{staticStyle:{"color":"#00a99e","font-size":"xx-large"},attrs:{"aria-hidden":"false"},on:{"click":function($event){return _vm.signOut()}}},[_vm._v(" mdi-logout ")])],1)],1)],1)],1)],1)])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }